import { axios, API_ROUTES } from "@/config/index.js";

export default {
	async getAll(brandId) {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 0,
		}
		const url = API_ROUTES.device.get.replace(':id', brandId)

		const { data } = await axios.get(url, params)

		return data
	},
}
